<template>
  <div class="trips-head mt-10">
    <div class="max-width">
      <h2 class="my-5 primary--text">{{$t("navbar.trips")}}</h2>
      <div class="d-flex align-center justify-space-between">
        <!-- filters -->
        <v-row align="center">
          <v-col cols="12" md="3" sm="4">
            <!-- from -->
            <base-select
              :placeholder="$t('from')"
              :items="countriesFilter"
              v-model="filter.from"
              itemValue="name"
              itemText="name"
              :rules="[]"
              @input="searchHandler"
            ></base-select>
          </v-col>

          <v-col cols="12" md="3" sm="4" >
            <!-- to -->
            <base-select
              :placeholder="$t('to')"
              :items="countriesFilter"
              v-model="filter.to"
              itemValue="name"
              itemText="name"
              :rules="[]"
              @input="searchHandler"
            ></base-select>
          </v-col>

          <v-col cols="12" md="3" sm="4" >
            <!-- category -->
            <base-select
              :placeholder="$t('category')"
              :items="categoriesFilter"
              itemValue="id"
              itemText="name"
              v-model="filter.category"
              :rules="[]"
              @input="searchHandler"
            ></base-select>
          </v-col>

          <v-col cols="12"  md="3" sm="6" class="d-flex justify-end">
            <!-- add new -->
              <v-btn
                class="c-btn px-1"
                color="primary"
                elevation="0"
                height="35"
                width="100%"
                block
                @click="$router.push('/add-trip')"
                ><span class="font-300 font-20 white--text d-block "
                  ><span class="font-25 mr-2">+</span>{{$t('addYourTrip')}}</span
                ></v-btn
              >
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- head -->

  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import SectionHead from "../../../components/core/SectionHead.vue";
export default {
  components: {  },
  data: () => ({
    filter: {},
  }),
  computed: {
    ...mapGetters(["countriesFilter", "categoriesFilter"]),
  },
  methods: {
    searchHandler() {
      this.$emit("searchHandler", this.filter);
    },
  },
};
</script>

<style lang="scss">
.trips-head{
  .verification-page__head {
    margin-top: 20px !important
  }
}
</style>
