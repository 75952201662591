<template>
  <div class="trips-page">
    <v-container>
      <trip-head @searchHandler="searchHandler"></trip-head>
      <trip-content
        :data="data"
        :isSearch="isSearch"
        :loading="loading"
      ></trip-content>

      <!-- pagination -->
      <div class="max-width mt-10">
        <div class="d-flex justify-center">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="isSearch ? searchHandler(filter, $event) : getData($event)"
            color="primary"
          ></v-pagination>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import TripHead from "./components/HeadFilters.vue";
import TripContent from "./components/Content.vue";
export default {
  components: {
    TripHead,
    TripContent,
  },
  data: () => ({
    isSearch: false,
    data: [],
    totalPages: 0,
    page: 1,
    filter: {},
    loading: false,
  }),
  methods: {
    async searchHandler(filter, number = 1) {
      this.loading = true;

      this.filter = { ...filter };
      let { data } = await this.$axios.get(
        `/trips?page=${number}${
          this.filter.from && this.filter.from != "All"
            ? "&from=" + this.filter.from
            : ""
        }${
          this.filter.to && this.filter.to != "All"
            ? "&to=" + this.filter.to
            : ""
        }${
          this.filter.category && this.filter.category != "All"
            ? "&category_id=" + this.filter.category
            : ""
        }
        `
      );
      if (data.data) {
        this.isSearch = true;
        this.data = [
          ...data.data.trips.map((item) => {
            return {
              id: item.id,
              bringer_name: item.bringer_name,
              title: item.title,
              image:
                item.bringer_image == "#"
                  ? this.$defaultImage
                  : item.bringer_image,
              images: [],
              fromCountry: item.origin_country,
              toCountry: item.destination_country,
              fromCity: item.origin_city,
              toCity: item.destination_city,
              date: item.expected_date,
              categories: item.categories,
              weight: item.weight,
              rate: +item.rate,
              owner_id: item.owner_id,
            };
          }),
        ];
        this.page = number;
        this.totalPages = Math.ceil(data.data.total / data.data.per_page);
      }
      this.loading = false;
    },
    async getData(number = 1) {
      this.loading = true;
      let { data } = await this.$axios.get(`/trips?page=${number}`);
      if (data.data) {
        this.totalPages = Math.ceil(data.data.total / data.data.per_page);
        this.data = [
          ...data.data.trips.map((item) => {
            return {
              id: item.id,
              bringer_name: item.bringer_name,
              title: item.title,
              image:
                item.bringer_image == "#"
                  ? this.$defaultImage
                  : item.bringer_image,
              images: [],
              fromCountry: item.origin_country,
              toCountry: item.destination_country,
              fromCity: item.origin_city,
              toCity: item.destination_city,
              date: item.expected_date,
              categories: item.categories,
              weight: item.weight,
              rate: +item.rate,
              owner_id: item.owner_id,
            };
          }),
        ];
      }

      this.loading = false;
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
