<template>
  <div class="trip-content">
    <div class="max-width">
      <!-- <div class="text-center">
        <span
          v-if="!isSearch"
          class="font-30 font-500 primary--text d-block mt-14"
        >
          - All Trips -
        </span>
        <span
          v-if="isSearch"
          class="font-30 font-500 primary--text d-block"
          @click="getItems"
        >
          All Trips > Search Results
        </span>
      </div> -->

      <v-row class="mt-4" v-if="!loading && data.length > 0">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="4"
          v-for="(item, i) in data"
          :key="i"
        >
          <product-card
            :item="item"
            :route="
              userID == item.owner_id
                ? '/trip/details/' + item.id
                : '/trips/' + item.id
            "
          >
          </product-card>
        </v-col>
      </v-row>

      <!-- if empty -->
      <no-items v-if="!loading && data.length == 0"></no-items>

      <!-- if loading -->
      <v-row v-if="loading" class="mt-4">
        <v-col cols="12" md="4" v-for="n in 3" :key="n">
          <skeleton-card />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ProductCard from "../../../components/core/ProductCard.vue";
export default {
  components: {
    ProductCard,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    totalPages: 0,
    page: 0,
  }),
  computed: {
    userID() {
      return localStorage.getItem("userID");
    },
  },
  methods: {
    getItems() {},
  },
};
</script>

<style></style>
